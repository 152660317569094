.index{
    background-color: black;
}

.black-bg{
    background-color: black;
    height: 100vh;
    width: 100vh;
}
@font-face {
  font-family: 'Cramps'; /* The name you want to give the font */
  src: url('../../public/CrampsRegular-OV7od.ttf') format('truetype'); /* Path to the font file */
  font-weight: normal; /* You can define weight and style here */
  font-style: normal;
}

.typing-text {
  font-family: 'Cramps', monospace;
  font-size: 2rem;
  color: #B37536;
  white-space: nowrap;
  /* overflow: hidden; */
  /* width: 0; */
  display: inline-block;
}

.typing{
    position: fixed; /* Fixes the container position */
    top: 0; /* Aligns to the top */
    left: 0; /* Aligns to the left */
    width: 100vw; /* Takes up the full width of the viewport */
    height: 100vh; /* Takes up the full height of the viewport */
    background-color: black; /* Black background */
    display: flex; /* Flexbox to center content */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    text-align: center; /* Centers the text inside the container */
    z-index: 9999; /* Ensures it appears above other content */
}

@keyframes tvOff {
  0% {
    transform: scaleY(.6);
    opacity: 1;
  }
  80% {
    transform: scaleY(0.02);
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}

.tv-screen {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tv-content {
  width: 100%;
  height: 100%;
  background-color: green;
  animation: tvOff 2s forwards; /* Adjust timing here */
  display: flex;
  align-items: center;
  justify-content: center;
}

.amber{
    opacity: 0;
    transition: opacity 1s ease-in; /* Smooth transition */
    background-color: black;
    position: fixed; /* Fixes the container position */
    top: 0; /* Aligns to the top */
    left: 0; /* Aligns to the left */
    width: 100vw; /* Takes up the full width of the viewport */
    height: 100vh; /* Takes up the full height of the viewport */
    display: flex; /* Flexbox to center content */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    text-align: center; /* Centers the text inside the container */
    font-family: 'Courier', monospace;
    font-size: 3rem;
    color: #931200;
    white-space: nowrap;
    overflow: hidden;
}

.amber.fade-in {
  opacity: 1; /* Fade in to visible */
}

.main-image {
  width: 800px !important;
}

/* Fade to black effect */
.fade-to-black {
  animation: fadeToBlack 1s forwards; /* Duration of the fade-to-black */
}

@keyframes fadeToBlack {
  from {
    opacity: 1;
    background-color: transparent;
  }
  to {
    opacity: 0;
    background-color: black;
  }
}

